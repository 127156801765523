<template>
  <div>
    <div class="event-board">
      <div class="event-checked">
        <img
          v-if="completeEvent"
          class="lucky-after"
          src="https://liveinfo3storageprod.blob.core.windows.net/publishing/event1121_Lucky_Random_Box_after.png"
          alt="행운의 랜덤박스 오픈 후"
        >
      </div>
      <img
        v-if="!completeEvent"
        src="https://liveinfo3storageprod.blob.core.windows.net/publishing/event1121_Lucky_Random_Box_before.png"
        usemap="#Lucky-map"
        alt="행운의 랜덤박스 오픈 전"
      >
      <map name="Lucky-map">
        <area
          class="checked"
          data-target="lucky-after"
          coords="289, 456, 699, 676"
          shape="rect"
          title="랜덤박스 클릭"
          @click.stop="createEventHistory"
        >
      </map>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { changeUserPoint } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import { numberFormatter } from '@/libs/utils/filters'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      completeEvent: false,
      historyCount: 0,
      isProcessing: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    userName() {
      if (this.userName) this.fetchEventHistory()
      if (this.userName) this.fetchEventComplete()
    },
  },
  created() {
    if (this.userName) this.fetchEventHistory()
    if (this.userName) this.fetchEventComplete()
  },
  methods: {
    fetchEventHistory() {
      axios.get(`/fu/event/hiscount/${this.eventId}`)
        .then(rs => {
          this.historyCount = rs.data
        })
        .catch(() => {
        })
    },
    fetchEventComplete() {
      axios.get(`/fu/event/complete/${this.eventId}/${this.contentId}`)
        .then(rs => {
          if (rs.data) {
            this.completeEvent = true
          } else this.completeEvent = false
        })
        .catch(() => {
        })
    },
    createEventHistory() {
      if (this.userName) {
        if (this.isProcessing) return

        this.isProcessing = true

        if (!this.completeEvent) {
          this.$parent.showConfirmDialog('랜덤 박스를 열어 보시겠어요?', (result) => {
            if (result) {
              axios.post('/fu/event/history', {
                EventId: this.eventId,
                ContentId: this.contentId,
              })
                .then(rs => {
                  setStatistics(121, this.eventId)

                  changeUserPoint(rs.data.pointBalance)

                  this.fetchEventHistory()

                  const messages = rs.data.awardPoint === 0
                    ? '아쉽지만, 꽝이에요! 다음 콘텐츠에서 다시 한번 참여해보세요.'
                    : `축하드립니다! 행운의 랜덤 박스 이벤트에 참여하여 ${numberFormatter(rs.data.awardPoint)}포인트가 지급되었습니다.`

                  this.$parent.showAlertDialog(messages, () => {
                    this.isProcessing = false
                    this.completeEvent = true
                  })
                })
                .catch(() => {
                  this.isProcessing = false

                  this.$parent.showAlertDialog('이벤트 처리 진행 중 오류가 발생했습니다.\n관리자에게 문의하여 주시기 바랍니다.')
                })
            } else {
              this.isProcessing = false
            }
          })
        } else {
          this.$parent.showAlertDialog('이미 참여한 컨텐츠 입니다.')
          this.isProcessing = false
        }
      } else {
        this.isProcessing = false

        this.redirectLogin()
      }
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => {})
    },
  }
}
</script>

<style scoped>
  body {
      margin: 0;
      padding: 0;
  }
  img {
      display: block;
      margin: 0 auto;
  }
  area:hover {
      cursor: pointer;
  }
  .event-board {
      width: 1000px;
      margin: 0 auto;
      position: relative;
  }
</style>
