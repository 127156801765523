var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "event-board" }, [
      _c("div", { staticClass: "event-checked" }, [
        _vm.completeEvent
          ? _c("img", {
              staticClass: "lucky-after",
              attrs: {
                src: "https://liveinfo3storageprod.blob.core.windows.net/publishing/event1121_Lucky_Random_Box_after.png",
                alt: "행운의 랜덤박스 오픈 후",
              },
            })
          : _vm._e(),
      ]),
      !_vm.completeEvent
        ? _c("img", {
            attrs: {
              src: "https://liveinfo3storageprod.blob.core.windows.net/publishing/event1121_Lucky_Random_Box_before.png",
              usemap: "#Lucky-map",
              alt: "행운의 랜덤박스 오픈 전",
            },
          })
        : _vm._e(),
      _c("map", { attrs: { name: "Lucky-map" } }, [
        _c("area", {
          staticClass: "checked",
          attrs: {
            "data-target": "lucky-after",
            coords: "289, 456, 699, 676",
            shape: "rect",
            title: "랜덤박스 클릭",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.createEventHistory($event)
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }